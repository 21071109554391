import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useSetRecoilState } from 'recoil';

import { GET_USER } from '../apollo/User';
import Sidebar from '../components/navigation/Sidebar';
import { userInfo } from '../states/atoms';

const DashboardLayoutNoMargins = () => {
  const setUser = useSetRecoilState(userInfo);
  const { user } = useAuth0();

  useQuery(GET_USER, {
    variables: { auth0_id: user?.sub },
    onCompleted: (data) => {
      setUser(data.getUser.data);
    },
    onError: (error) => {
      console.log(error, 'error user');
    },
    skip: !user?.sub,
  });

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex overflow-hidden">
        <Sidebar />
        <div
          className={`size-full text-blacker min-h-screen transition-all duration-1000 pl-[58px] `}
          style={{
            backgroundImage: 'url("/images/background-layout.svg")',
            backgroundRepeat: 'repeat',
            backgroundPosition: 'top -200px right -400px',
          }}
        >
          <div className="size-full">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayoutNoMargins;
