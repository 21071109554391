import type { FocusEvent } from 'react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Button from './Button';

interface IDescriptionInput {
  onSubmit: (val: string) => void;
  loading: boolean;
  disabled?: boolean;
}

const DescriptionInput = ({ onSubmit, disabled }: IDescriptionInput) => {
  const [showButtons, setShowButton] = useState(false);
  const { register, watch, resetField } = useFormContext();
  const { description } = watch();
  const onBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    if (event.relatedTarget?.className.includes('cancel-button')) {
      resetField('description');
      setShowButton(false);
    } else {
      onSubmit(description);
      setShowButton(false);
      resetField('description', { defaultValue: description });
    }
  };
  return (
    <div className="flex flex-col gap-y-2">
      <div className={`flex flex-col gap-y-1 w-full `}>
        <textarea
          placeholder={'Description Text'}
          {...register('description', { required: true })}
          className={`text-sm min-h-[70px]`}
          onFocus={() => setShowButton(true)}
          onBlur={onBlur}
          disabled={disabled}
        />
      </div>
      <div className="flex flex-row self-start gap-x-2">
        {showButtons && (
          <>
            <Button
              extraClassname="cancel-button"
              style="black-cancel"
              text="Cancel Changes"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default DescriptionInput;
