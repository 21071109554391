import type { ReactNode } from 'react';

import CloseIcon from '../assets/x-circle.svg';

interface PopupLayoutProps {
  handleClosePopup: () => void;
  children: ReactNode;
  popupClassname?: string;
  bgStyle?: 'blur' | 'dark';
}

const PopupLayout = ({
  handleClosePopup,
  children,
  popupClassname,
  bgStyle = 'blur',
}: PopupLayoutProps) => {
  return (
    <div
      className={`fixed z-[99] inset-0 flex items-center justify-center popup-bg 
      ${bgStyle === 'blur' ? 'backdrop-blur-[7.5px]' : 'bg-black/40'}
      `}
      onClick={(e) => {
        const target = e.target as HTMLDivElement;
        if (target.classList.contains('popup-bg')) {
          handleClosePopup();
        }
      }}
    >
      <div
        className={`max-h-[95vh] flex flex-col gap-6 items-center bg-white p-7 rounded-[5px] shadow-card relative`}
      >
        <div className={`w-full ${popupClassname || ''}`}>
          <button
            className="absolute right-1.5 top-1.5 cursor-pointer"
            onClick={handleClosePopup}
          >
            <img src={CloseIcon} alt="Close" width={20} height={20} />
          </button>
          {children}
        </div>
      </div>
    </div>
  );
};

export default PopupLayout;
