import type { UseFormRegisterReturn } from 'react-hook-form';

interface GenericInputProps {
  type: any;
  register: UseFormRegisterReturn<any>;
  title?: string;
  placeholder?: string;
  inputClassName?: string;
  error?: string;
  readonly?: boolean;
  onBlur?: () => void;
}

const GenericInput = ({
  type,
  register,
  title,
  placeholder,
  inputClassName,
  error,
  readonly,
  onBlur,
}: GenericInputProps) => {
  if (readonly) {
    return (
      <div className={`flex flex-col gap-y-1 w-full `}>
        {title && <p className={`text-light-gray-text text-xs`}>{title}</p>}
        <input
          placeholder={placeholder}
          type={type}
          {...register}
          disabled
          className={`text-sm disabled:bg-transparent ${error && 'border-b border-red'} ${inputClassName}`}
        ></input>
      </div>
    );
  }
  if (type === 'textarea') {
    return (
      <div className={`flex flex-col gap-y-1 w-full `}>
        {title && <p className={`text-light-gray-text text-xs`}>{title}</p>}
        <textarea
          placeholder={placeholder}
          {...register}
          className={`text-sm min-h-[70px] ${inputClassName}`}
        />
      </div>
    );
  }
  return (
    <div className={`flex flex-col gap-y-1 w-full `}>
      {title && <p className={`text-light-gray-text text-xs`}>{title}</p>}
      <input
        placeholder={placeholder}
        type={type}
        {...register}
        onBlur={onBlur}
        className={`text-sm ${error && 'border-b border-red'} ${inputClassName}`}
      ></input>
    </div>
  );
};

export default GenericInput;
