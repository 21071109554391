import { gql } from '@apollo/client';

export const GET_USER = gql`
  query GetUser($auth0_id: String) {
    getUser(auth0_id: $auth0_id) {
      status
      code
      error
      data {
        id
        auth0_id
        email
        username
        role
        client_id
        createdAt
        updatedAt
        deletedAt
        client {
          id
          client_name
          logo
          stripe_id
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  }
`;
