import ClockIcon from '../../assets/clock-icon.svg';
import Button from '../inputs/Button';
import PriorityIcon from './PriorityIcon';

interface TaskPanelProps {
  title: string;
  priority?: string | null;
  minHours?: number;
  maxHours?: number;
  isBacklog: boolean;
  onScheduleTask?: () => void;
}

const TaskCard = ({
  title,
  priority,
  minHours,
  maxHours,
  isBacklog,
  onScheduleTask,
}: TaskPanelProps) => {
  const onClickSchedule = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (onScheduleTask) onScheduleTask();
  };
  return (
    <>
      <div className={`py-2 bg-transparent`}>
        <div className="flex flex-col w-full px-1.5 py-1 h-[75px] shadow-card text-sm rounded-[5px] justify-between bg-white text-dark-blue">
          <p className="card-title w-full">{title}</p>
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center gap-x-2">
              {priority !== 'NO_PRIORITY' && (
                <div>
                  <PriorityIcon priority={priority} />
                </div>
              )}
              <div>
                {minHours && maxHours ? (
                  <p className="font-bold">{`${minHours || '?'} - ${maxHours || '?'}h`}</p>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {isBacklog && priority !== 'NO_PRIORITY' && (
              <div className="!text-white">
                <Button
                  icon={<img src={ClockIcon} alt="" />}
                  text="Schedule task"
                  style="blue"
                  onClick={onClickSchedule}
                  fontSize="text-xs"
                  disabled={!minHours || !maxHours}
                  extraClassname="disabled:opacity-50 disabled:bg-gray-700 disabled:cursor-default"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskCard;
