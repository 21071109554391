import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { CREATE_TASK } from '../../apollo/Tasks';
import Button from '../../components/inputs/Button';
import GenericInput from '../../components/inputs/GenericInput';
import PrioritySelectInput from '../../components/inputs/PrioritySelectInput';
import { PRIORITIES_OPTIONS } from '../../config/constants';
import { TASKS_NOTIFICATIONS } from '../../config/Notifications';
import PopupLayout from '../../layouts/PopupLayout';

interface CreateTaskPopupProps {
  visible: boolean;
  closePopup: () => void;
  priority?: string;
  project_id: string;
  refetch: () => void;
}

interface IForm {
  title: string;
  priority?: { label: string; value: string };
  description: string;
}

const CreateTaskPopup = ({
  visible,
  closePopup,
  priority,
  project_id,
  refetch,
}: CreateTaskPopupProps) => {
  const [createTask, { loading }] = useMutation(CREATE_TASK);
  // FORM
  const methods = useForm<IForm>({
    defaultValues: {
      priority: PRIORITIES_OPTIONS.find((o) => o.value === priority),
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    setValue(
      'priority',
      PRIORITIES_OPTIONS.find((o) => o.value === priority),
    );
  }, [priority]);

  if (!visible) return <></>;

  const onClose = () => {
    reset();
    closePopup();
  };

  const onSubmit = (data: IForm) => {
    createTask({
      variables: {
        project_id,
        title: data.title,
        description: data.description,
        priority: data.priority?.value || 'NO_PRIORITY',
      },
      onCompleted: () => {
        toast.success(TASKS_NOTIFICATIONS.CREATE_OK);
        refetch();
        onClose();
      },
      onError: () => {
        toast.error(TASKS_NOTIFICATIONS.CREATE_KO);
      },
    });
  };

  return (
    <PopupLayout popupClassname="md:w-[650px]" handleClosePopup={onClose}>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-x-1.5 gap-y-2.5"
        >
          <div className="flex flex-row gap-y-2.5 gap-x-5">
            <div className="w-full flex flex-col gap-y-1.5">
              <div className="!text-xl">
                <GenericInput
                  register={{ ...register('title', { required: true }) }}
                  type={'text'}
                  inputClassName="!text-dark-blue !text-xl !font-bold"
                  placeholder="Task Summary"
                  error={errors.title?.type}
                />
              </div>
              <GenericInput
                register={{ ...register('description', { required: true }) }}
                type={'textarea'}
                placeholder="Description Text"
              />
            </div>
            <div className="min-w-[240px] flex flex-col gap-y-2.5">
              <PrioritySelectInput
                name="priority"
                title="Priority"
                placeholder={'Priority'}
                options={PRIORITIES_OPTIONS}
              />
            </div>
          </div>
          <div className="w-full flex justify-end">
            <Button text="Create Task" loading={loading} type="submit" />
          </div>
        </form>
      </FormProvider>
    </PopupLayout>
  );
};

export default CreateTaskPopup;
