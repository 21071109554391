import { useMutation } from '@apollo/client';
import type { DropResult } from '@hello-pangea/dnd';
import { DragDropContext } from '@hello-pangea/dnd';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { MOVE_TASK } from '../../apollo/Tasks';
import { TASKS_NOTIFICATIONS } from '../../config/Notifications';
import type { IColumn } from '../../types/Projects';
import { getPriorityFromColumnStatus } from '../../utils/tasks';
import GenericConfirmationPopup from '../../views/Popups/GenericConfirmationPopup';
import Loader from '../loaders/RippleLoader';
import AvailableHours from './AvailableHours';
import Panel from './Panel';

interface FeaturesTaskRequestsProps {
  data: IColumn[] | null;
  setData: (val: IColumn[]) => void;
  loading: boolean;
  maxHours: number;
  currentHours: number;
  setCurrentHours: (val: number) => void;
  refetch: () => void;
  project_id: string;
}

const FeaturesTaskRequests = ({
  setData,
  data,
  loading,
  maxHours,
  currentHours,
  refetch,
  setCurrentHours,
  project_id,
}: FeaturesTaskRequestsProps) => {
  const [moveTask, { loading: loadingMove }] = useMutation(MOVE_TASK);
  const [schedulePopup, setSchedulePopup] = useState<{
    onClick: (() => void) | null;
    isBacklog: boolean | null;
  }>({
    onClick: null,
    isBacklog: null,
  });

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    let needsPopup = false;
    let isBacklog = false;
    let newCurrentHours = currentHours;
    const { destination, source } = result;
    const scheduleDrop = destination.droppableId.includes('-schedule');
    const newData: IColumn[] = JSON.parse(JSON.stringify([...data!]));
    const variables: any = {};
    if (destination.droppableId === source.droppableId) {
      // SAME COLUMN AND SAME INDEX (NO MOVE)
      if (destination.index === source.index) return;
      // SAME COLUMN DIFFERENT ORDER
      const columnIndex = newData.findIndex(
        (c) => c.id === destination.droppableId,
      );
      if (columnIndex < 0) return;
      const newTasks = Array.from(newData[columnIndex]?.tasks || []);
      // Remove task from past position
      const [draggedItem] = newTasks.splice(result.source.index, 1);
      // Add task in new position
      newTasks.splice(destination.index, 0, draggedItem);
      // Update new order
      newData[columnIndex].tasks = newTasks.map((t, i) => ({
        ...t,
        order: i + 1,
      }));

      // SEND UPDATE TASKS FOR newData[columnIndex].tasks or draggedItem with new order
      const movedTask = newData[columnIndex].tasks[destination.index];
      variables.id = movedTask.id;
      variables.order = movedTask.order;
      variables.scheduledAt = movedTask.scheduledAt;
    } else {
      // MOVE FROM ONE COLUMN TO ANOTHER
      const oldColumnIndex = newData.findIndex(
        (c) => c.id === source.droppableId.replace('-schedule', ''),
      );
      const newColumnIndex = newData.findIndex(
        (c) => c.id === destination.droppableId.replace('-schedule', ''),
      );

      const draggedItem = { ...newData[oldColumnIndex].tasks[source.index] };

      // Without this line scheduled at gets deleted from database
      variables.scheduledAt = draggedItem.scheduledAt;

      if (newData[newColumnIndex].backlog !== newData[oldColumnIndex].backlog) {
        // Changed from backlog to scheduled or the way round
        needsPopup = true;
        if (newData[oldColumnIndex].backlog) isBacklog = true;
        if (
          !newData[oldColumnIndex].finished &&
          newData[newColumnIndex].backlog
        ) {
          // Moved back to backlog
          variables.scheduledAt = null;
          draggedItem.scheduledAt = undefined;
          newCurrentHours =
            currentHours - (draggedItem.min_hours_estimated || 0);
          if (!needsPopup) setCurrentHours(newCurrentHours);
        } else if (
          !newData[newColumnIndex].backlog &&
          !newData[newColumnIndex].finished
        ) {
          // Move from backlog to scheduled to a non finished column if has hours estimated and current planned
          // hours do not exceed max hours for the project
          if (
            !draggedItem.min_hours_estimated ||
            !draggedItem.max_hours_estimated
          ) {
            toast.error(TASKS_NOTIFICATIONS.MOVE_NO_HOURS_ERROR, {
              autoClose: 5000,
            });
            if (data) setData([...data]);
            return;
          }
          newCurrentHours =
            currentHours + (draggedItem.min_hours_estimated || 0);
          if (currentHours <= maxHours && newCurrentHours > maxHours) {
            if (!needsPopup)
              toast.warn(TASKS_NOTIFICATIONS.MOVE_MAX_HOURS_WARNING, {
                autoClose: 5000,
              });
          } else if (currentHours > maxHours) {
            toast.error(TASKS_NOTIFICATIONS.MOVE_MAX_HOURS_ERROR, {
              autoClose: 5000,
            });
            return;
          }
          variables.scheduledAt = new Date();
          draggedItem.scheduledAt = new Date().getTime().toString();
          if (!needsPopup) setCurrentHours(newCurrentHours);
        }
      }

      if (newData[newColumnIndex].column_status) {
        // Changed from backlog to scheduled or the way round
        const newPriority = getPriorityFromColumnStatus(
          newData[newColumnIndex].column_status,
        );
        if (newPriority !== undefined) {
          variables.priority = newPriority;
          draggedItem.priority = newPriority;
        }
      }

      // Moved from finished column to non finisched or the way round
      if (
        newData[newColumnIndex].backlog === newData[oldColumnIndex].backlog &&
        !newData[newColumnIndex].backlog &&
        newData[newColumnIndex].finished !== newData[oldColumnIndex].finished
      ) {
        newCurrentHours = currentHours;
        if (newData[newColumnIndex].finished) {
          // Moved to finished column
          newCurrentHours =
            currentHours - (draggedItem.min_hours_estimated || 0);
        } else {
          // Moved to non finished column
          newCurrentHours =
            currentHours + (draggedItem.min_hours_estimated || 0);
        }
        setCurrentHours(newCurrentHours);
      }

      // Update old column without task and new order
      newData[oldColumnIndex].tasks.splice(source.index, 1);
      newData[oldColumnIndex].tasks = newData[oldColumnIndex].tasks.map(
        (t, i) => ({ ...t, order: i + 1 }),
      );

      draggedItem.column_id = destination.droppableId.replace('-schedule', '');

      // Update new column with new task and new order
      newData[newColumnIndex].tasks.splice(
        scheduleDrop ? newData[newColumnIndex].tasks.length : destination.index,
        0,
        draggedItem,
      );
      newData[newColumnIndex].tasks = newData[newColumnIndex].tasks.map(
        (t, i) => ({ ...t, order: i + 1 }),
      );

      // SEND UPDATE TASKS FOR newData[columnIndex].tasks or draggedItem with new order
      const movedTask =
        newData[newColumnIndex].tasks[
          scheduleDrop
            ? newData[newColumnIndex].tasks.length - 1
            : destination.index
        ];
      variables.id = movedTask.id;
      variables.order = movedTask.order;
      variables.scheduledAt = movedTask.scheduledAt;
      variables.column_id = movedTask.column_id;
    }
    // If popup needs to be shown, wait for onClick function to be fired to
    // send new data to the backend and udpdate the client
    if (needsPopup) {
      setSchedulePopup({
        onClick: () => {
          moveTask({
            variables,
            onError: () => {},
            onCompleted: () => {
              setSchedulePopup({ onClick: null, isBacklog: null });
              setCurrentHours(newCurrentHours);
              if (currentHours <= maxHours && newCurrentHours > maxHours) {
                toast.warn(TASKS_NOTIFICATIONS.MOVE_MAX_HOURS_WARNING, {
                  autoClose: 5000,
                });
              }
            },
          });
          setData(newData);
        },
        isBacklog,
      });
      return;
    }
    moveTask({ variables, onError: () => {} });
    setData(newData);
  };

  return (
    <>
      {loading && (
        <div className="w-full h-screen items-center justify-center">
          <Loader />
        </div>
      )}
      {data && (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Panel
            handleOnDragEnd={handleOnDragEnd}
            data={data.filter((c) => !c.backlog)}
            refetch={refetch}
            title="Scheduled Tasks Status"
            project_id={project_id}
            isBacklog={false}
            maxHours={maxHours}
            currentHours={currentHours}
          />
          <Panel
            handleOnDragEnd={handleOnDragEnd}
            data={data.filter((c) => c.backlog)}
            title="Backlog Tasks"
            refetch={refetch}
            project_id={project_id}
            isBacklog
            maxHours={maxHours}
            currentHours={currentHours}
            titleChildren={
              <AvailableHours currentHours={currentHours} maxHours={maxHours} />
            }
          />
        </DragDropContext>
      )}
      <GenericConfirmationPopup
        text={`Are you sure you want to ${schedulePopup.isBacklog ? 'schedule' : 'unschedule'} this task?`}
        description={
          schedulePopup.isBacklog
            ? 'This action is irreversible; once added to planned tasks, it cannot be moved back to the backlog.'
            : 'Moving this task from Planned to Backlog may affect client expectations and alter agreed-upon deliverables. The client will be notified of this change.'
        }
        onAccept={schedulePopup.onClick!}
        loading={loadingMove}
        closePopup={() => setSchedulePopup({ onClick: null, isBacklog: null })}
        visible={!!schedulePopup.onClick}
        bgStyle="blur"
      />
    </>
  );
};

export default FeaturesTaskRequests;
