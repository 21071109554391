import { gql } from '@apollo/client';

export const GET_ALL_PROJECTS = gql`
  query GetProject {
    getProjects {
      status
      code
      data {
        client_id
        client_name
        client_logo
        projects {
          project_id
          project_name
          project_key
          project_color
          project_slug
          project_logo
          project_type
        }
      }
    }
  }
`;

export const GET_SINGLE_PROJECTS = gql`
  query GetProject($slug: String) {
    getSingleProject(slug: $slug) {
      status
      code
      data {
        project_id
        project_name
        project_key
        project_color
        project_slug
        project_logo
        max_hours
        hoursEstimated
        createdAt
        updatedAt
        deletedAt
        columns {
          id
          project_id
          column_name
          column_status
          createdAt
          updatedAt
          deletedAt
          order
          finished
          backlog
          tasks {
            id
            project_id
            title
            description
            priority
            column_id
            max_hours_estimated
            min_hours_estimated
            is_visible
            order
            key
            scheduledAt
            createdAt
            updatedAt
            deletedAt
          }
        }
      }
    }
  }
`;
