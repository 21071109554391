import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { GET_ALL_PROJECTS } from '../apollo/Projects';
import Loader from '../components/loaders/RippleLoader';
import { Agent } from '../components/portal/Agent';
import { ProjectInfo } from '../components/portal/Project';
import { userInfo } from '../states/atoms';
import type { IGetProjectsRes, IProject } from '../types/Projects';

export default function Dashboard() {
  const user = useRecoilValue(userInfo);
  const [projects, setProjects] = useState<IProject[]>([]);
  const { loading } = useQuery<IGetProjectsRes>(GET_ALL_PROJECTS, {
    onCompleted: (data) => {
      const projectsRes = Array.from(
        data.getProjects.data.map((c) =>
          c.projects.map((p) => ({
            ...p,
          })),
        ),
      );
      setProjects(projectsRes.flat());
    },
    onError: (error) => {
      console.log(error, 'error user');
    },
  });
  return (
    <>
      <div className="flex flex-col">
        {user && (
          <div className="text-[#323B4B] font-inter text-2xl font-semibold md:text-left text-center">
            Welcome {user.username}!
          </div>
        )}
        <div className="mt-10 flex md:flex-row flex-col justify-between">
          <div className="w-full">
            <p className="text-[#323B4B] font-inter text-xl font-semibold leading-7 md:text-left text-center">
              Your Projects With Us
            </p>
            <div className="mt-5 flex md:flex-row flex-col items-center gap-5">
              {loading && (
                <div className="w-full items-center justify-center">
                  <Loader />
                </div>
              )}
              {projects.map((p) => (
                <ProjectInfo
                  category={p.project_type}
                  project={p.project_name}
                  slug={p.project_slug}
                  logo={p.project_logo}
                />
              ))}
            </div>
          </div>
          <div className="flex flex-col md:mt-0 mt-5 ">
            <p className="text-[#323B4B] font-inter text-xl font-semibold leading-7 mb-5 md:text-left text-center">
              Your Agent
            </p>
            <Agent
              name="Duncan Margalef"
              title="Sales Director US"
              picture="/images/duncan.svg"
              onClickEvent={() => {
                window.open('https://calendly.com/wedevio/meeting', '_blank');
              }}
            />
          </div>
        </div>

        {/* <div className="mt-10 md:flex hidden">
          <ProjectPayment></ProjectPayment>
        </div>
        <div className="mt-10 md:hidden flex">
          <ProjectPaymentMobile></ProjectPaymentMobile>
        </div> */}
        <div></div>
      </div>
    </>
  );
}
