export const ROUTES = {
  LOGOUT: '/logout',
  HOME: '/',
  DASHBOARD: '/dashboard',
  PROFILE: '/profile',
  PAYMENTS: '/payments',

  // FEATURES REQUEST
  FEATURES_REQUEST: '/features-request',
  SINGLE_FEATURES_REQUEST: '/features-request/:slug',
};
