import { Link } from 'react-router-dom';

import { ROUTES } from '../../config/routes/config';

interface ProjectInfoProps {
  project: string;
  client: string;
  logo: string;
  slug: string;
}

function ProjectRequestsCard({
  project,
  client,
  logo,
  slug,
}: ProjectInfoProps) {
  return (
    <Link to={ROUTES.SINGLE_FEATURES_REQUEST.replace(':slug', slug)}>
      <div className="w-[250px] h-[170px] py-5 px-3 flex flex-col rounded-md items-center justify-around shadow-card bg-white">
        <img
          src={`${process.env.REACT_APP_AWS_S3_PUBLIC_FOLDER}${logo}`}
          alt={project}
          width={120}
          height={70}
          className="object-contain h-[70px]"
        />
        <div className="text-center font-semibold leading-6 mt-2">
          <p className="text-base ">
            {client} - {project}
          </p>
        </div>
      </div>
    </Link>
  );
}

export default ProjectRequestsCard;
