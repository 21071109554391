import { useMutation } from '@apollo/client';
import type { DroppableProvided, DropResult } from '@hello-pangea/dnd';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { type ReactNode, useState } from 'react';
import { toast } from 'react-toastify';

import { SCHEDULE_TASK } from '../../apollo/Tasks';
import { EXCEED_HOURS_ERROR_MESSAGE } from '../../config/constants';
import { TASKS_NOTIFICATIONS } from '../../config/Notifications';
import type { ITask } from '../../types';
import type { IColumn } from '../../types/Projects';
import CreateTaskPopup from '../../views/Popups/CreateTaskPopup';
import GenericConfirmationPopup from '../../views/Popups/GenericConfirmationPopup';
import SingleTaskPopup from '../../views/Popups/SingleTaskPopup';
import Button from '../inputs/Button';
import TaskCard from './TaskCard';

interface PanelColumnProps {
  tasks: ITask[];
  provided?: DroppableProvided;
  isDraggingOver: boolean;
  columnTitle: string;
  isBacklog: boolean;
  onClickTask: (val: ITask | null) => void;
  onClickCreate: () => void;
  setSchedulePopup: (val: any) => void;
}

const PanelColumn = ({
  tasks,
  provided,
  isDraggingOver,
  columnTitle,
  isBacklog,
  onClickCreate,
  onClickTask,
  setSchedulePopup,
}: PanelColumnProps) => {
  return (
    <>
      <div
        className={`flex flex-col !rounded-[20px] w-[300px] px-4 py-3 shadow-[0px_0px_5px_0px_rgba(0,0,0,0.20)] h-fit ${isDraggingOver ? 'text-white bg-light-blue rounded-md' : 'text-dark-blue bg-light-gray-columns'}`}
      >
        <p className=" font-bold text-lg">{columnTitle}</p>
        <ul
          className={`list-none p-2`}
          ref={provided?.innerRef || undefined}
          {...provided?.droppableProps}
        >
          {isBacklog &&
            tasks.map((t, index) => (
              <Draggable
                isDragDisabled={!isBacklog}
                key={t.id}
                draggableId={t.id.toString()}
                index={index}
              >
                {(providedDraggable: any) => (
                  <li
                    ref={providedDraggable.innerRef}
                    {...providedDraggable.dragHandleProps}
                    {...providedDraggable.draggableProps}
                    onClick={() => onClickTask(t)}
                    className="!cursor-pointer"
                  >
                    <TaskCard
                      title={`${t.key} - ${t.title}`}
                      maxHours={t.max_hours_estimated!}
                      minHours={t.min_hours_estimated!}
                      priority={t.priority}
                      isBacklog={isBacklog}
                      onScheduleTask={() =>
                        setSchedulePopup({
                          taskMinHours: t.min_hours_estimated!,
                          taskMaxHours: t.max_hours_estimated!,
                          task_id: t.id,
                        })
                      }
                    />
                  </li>
                )}
              </Draggable>
            ))}
          {!isBacklog &&
            tasks.map((t) => (
              <li onClick={() => onClickTask(t)} className="!cursor-pointer">
                <TaskCard
                  title={`${t.key} - ${t.title} - ${t.order}`}
                  maxHours={t.max_hours_estimated!}
                  minHours={t.min_hours_estimated!}
                  priority={t.priority}
                  isBacklog={isBacklog}
                />
              </li>
            ))}
          {provided?.placeholder}
        </ul>
        {isBacklog && (
          <Button
            style="blue"
            text="Create Item"
            fontSize="text-sm"
            extraClassname="mx-2"
            onClick={() => onClickCreate()}
          />
        )}
        <div className="px-4"></div>
      </div>
    </>
  );
};

interface PanelProps {
  title: string;
  titleChildren?: ReactNode;
  handleOnDragEnd: (result: DropResult) => void;
  data: IColumn[];
  refetch: () => void;
  isBacklog: boolean;
  project_id: string;
  maxHours: number;
  currentHours: number;
}

const Panel = ({
  title,
  titleChildren,
  data,
  refetch,
  isBacklog,
  project_id,
  maxHours,
  currentHours,
}: PanelProps) => {
  const [editPopup, setEditPopup] = useState<ITask | null>(null);
  const [createPopup, setCreatePopup] = useState<string>('');
  const [schedulePopup, setSchedulePopup] = useState<{
    taskMinHours: number;
    taskMaxHours: number;
    task_id: string;
  } | null>(null);
  const [scheduleTask, { loading: loadingScheduleTask }] =
    useMutation(SCHEDULE_TASK);

  const onScheduleTask = (
    taskMinHours: number,
    taskMaxHours: number,
    task_id: string,
  ) => {
    if (!taskMinHours || !taskMaxHours) {
      toast.error(TASKS_NOTIFICATIONS.MOVE_NO_HOURS_ERROR);
      return;
    }
    // SCHEDULE TASK
    if (currentHours > maxHours) {
      toast.error(TASKS_NOTIFICATIONS.MOVE_MAX_HOURS_ERROR);
      return;
    }
    scheduleTask({
      variables: { id: task_id },
      onCompleted: () => {
        if (currentHours + (taskMinHours || 0) > maxHours)
          toast.warn(TASKS_NOTIFICATIONS.MOVE_MAX_HOURS_WARNING);
        else toast.success(TASKS_NOTIFICATIONS.TASK_SCHEDULED_OK);
        refetch();
        setSchedulePopup(null);
      },
      onError: (error) => {
        if (error.message === EXCEED_HOURS_ERROR_MESSAGE)
          toast.error(TASKS_NOTIFICATIONS.MOVE_MAX_HOURS_ERROR);
        else toast.error(TASKS_NOTIFICATIONS.TASK_SCHEDULED_KO);
      },
    });
  };

  return (
    <>
      {/* POPUPS */}
      {editPopup && (
        <SingleTaskPopup
          closePopup={() => setEditPopup(null)}
          visible={!!editPopup}
          refetch={refetch}
          task={editPopup}
          isBacklog={isBacklog}
          projectMaxHours={maxHours}
          currentHours={currentHours}
        />
      )}
      {isBacklog && (
        <CreateTaskPopup
          closePopup={() => setCreatePopup('')}
          visible={!!createPopup}
          priority={createPopup}
          project_id={project_id}
          refetch={refetch}
        />
      )}
      {isBacklog && (
        <GenericConfirmationPopup
          closePopup={() => setSchedulePopup(null)}
          visible={!!schedulePopup}
          onAccept={() =>
            schedulePopup &&
            onScheduleTask(
              schedulePopup?.taskMinHours,
              schedulePopup?.taskMaxHours,
              schedulePopup?.task_id,
            )
          }
          text={`Are you sure you want to schedule this task?`}
          acceptText="Schedule"
          description="This action is irreversible; once added to planned tasks, it cannot be moved back to the backlog."
          loading={loadingScheduleTask}
        />
      )}
      <div className="w-full flex flex-col">
        <div className="flex flex-col justify-between gap-y-2">
          <p className="text-dark-blue font-inter text-xl font-bold">{title}</p>
          {titleChildren}
        </div>
        <div className="mt-5 w-max">
          {/* Panel for Schedule tasks where there is only one big dropable zone for scheduling tasks */}
          {!isBacklog && (
            <Droppable
              isDropDisabled={isBacklog}
              droppableId={`${data[0].id}-schedule`}
            >
              {(providedSchedule: any, snapshotSchedule: any) => (
                <div
                  {...providedSchedule.droppableProps}
                  ref={providedSchedule.innerRef}
                  className={`flex flex-row w-full gap-x-5 md:px-0 px-1 relative`}
                >
                  {data.map((c) => (
                    <PanelColumn
                      tasks={c.tasks.sort((a, b) => a.order - b.order)}
                      provided={undefined}
                      isDraggingOver={false}
                      columnTitle={c.column_name}
                      isBacklog={isBacklog}
                      setSchedulePopup={setSchedulePopup}
                      onClickTask={setEditPopup}
                      onClickCreate={() =>
                        setCreatePopup(c.column_status || '')
                      }
                    />
                  ))}
                  {snapshotSchedule.isDraggingOver && (
                    <div className="absolute top-0 left-0 size-full bg-light-blue/50 rounded-[5px] p-5 text-white flex items-center justify-center">
                      <div className="size-full flex items-center justify-center border-4 rounded-[5px] border-dashed border-white">
                        Drop to Schedule Task
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Droppable>
          )}
          {isBacklog && (
            <div className={`flex flex-row w-full gap-x-5 md:px-0 px-1 `}>
              {data.map((c) => (
                <Droppable
                  isDropDisabled={!isBacklog}
                  key={c.id}
                  droppableId={c.id}
                >
                  {(providedColumn: any, snapshotColumn: any) => (
                    <PanelColumn
                      tasks={c.tasks.sort((a, b) => a.order - b.order)}
                      provided={providedColumn}
                      isDraggingOver={snapshotColumn.isDraggingOver}
                      columnTitle={c.column_name}
                      setSchedulePopup={setSchedulePopup}
                      isBacklog={isBacklog}
                      onClickTask={setEditPopup}
                      onClickCreate={() =>
                        setCreatePopup(c.column_status || '')
                      }
                    />
                  )}
                </Droppable>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Panel;
