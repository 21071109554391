import dayjs from 'dayjs';

import { PRIORITIES_OPTIONS } from '../config/constants';
import type { ITask } from '../types';

const advancedFormat = require('dayjs/plugin/advancedFormat');

dayjs.extend(advancedFormat);

export const getPriorityFromColumnStatus = (column_status: string | null) => {
  if (column_status === 'NO_PRIORITY') return null;
  if (column_status) return column_status;
  return undefined;
};

export const formatTaskPopup = (task: ITask) => {
  return {
    ...task,
    createdAt: dayjs(Number(task.createdAt)).format('MMMM Do YYYY - hh:mm A'),
    scheduledAt: task.scheduledAt
      ? dayjs(Number(task.scheduledAt)).format('MMMM Do YYYY - hh:mm A')
      : ' - ',
    priority: PRIORITIES_OPTIONS.find((p) => p.value === task.priority),
  };
};
