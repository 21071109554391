import React from 'react';

import ArrowRight from '../../assets/arrow-right.svg';
import Button from '../inputs/Button';

interface AgentProps {
  name: string;
  title: string;
  picture: string;
  onClickEvent?: () => void;
}

export function Agent({
  name,
  title,
  picture,
  onClickEvent = () => {},
}: AgentProps) {
  return (
    <div className="min-h-[71px] max-w-[525px] flex md:flex-row flex-col items-center md:min-w-[525px]">
      <img alt="" src={picture} width={71} height={71}></img>
      <div className="flex flex-col font-semibold leading-6 mt-2 mx-auto">
        <p className="md:mt-0 mt-2 text-xl">{name}</p>
        <p className="text-base text-center">{title}</p>
      </div>
      <Button
        onClick={onClickEvent}
        text="Schedule a Meeting"
        type="button"
        extraClassname="!flex-row-reverse"
        icon={
          <img
            alt=""
            src={ArrowRight}
            width={16}
            height={16}
            className="object-cover"
          />
        }
      />
    </div>
  );
}
