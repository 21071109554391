import { atom } from 'recoil';

export const menuSidebarOpenState = atom({
  key: 'menuSidebarOpen', // unique ID
  default: false, // default value
});

export const apolloErrorModal = atom({
  key: 'apolloErrorModal', // unique ID
  default: {
    errors: [],
    show: false,
  } as { errors: string[]; show: boolean }, // default value
});

export const userRole = atom({
  key: 'userRole', // unique ID
  default: 'LoanOfficer', // default value
});

interface Role {
  name: string;
  id: string;
}
export const roles = atom({
  key: 'roles', // unique ID
  default: [] as Role[], // default value
});

export const tokenStateAtom = atom<string | undefined>({
  key: 'tokenState',
  default: undefined,
});

export const userInfo = atom<any>({
  key: 'userInfo',
  default: undefined,
});
