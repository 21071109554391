import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import CheckAuth from '../../components/auth/CheckAuth';
import RequireAuth from '../../components/auth/RequireAuth';
import DashboardLayout from '../../layouts/DashboardLayout';
import DashboardLayoutNoMargins from '../../layouts/DashboardLayoutNoMargins';
import Dashboard from '../../views/Dashbord';
import FeaturesRequest from '../../views/MainView/FeaturesRequest';
import SingleFeatureRequest from '../../views/SingleView/SingleFeatureRequest';
import { ROUTES } from './config';
// import Payments from '../../views/payments';

const AppRouter = () => {
  // const {} = usePermissions();

  const location = useLocation();
  useEffect(() => {
    // Reset States according to location
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="/" element={<CheckAuth />} />
        <Route
          element={
            <RequireAuth>
              <DashboardLayout />
            </RequireAuth>
          }
        >
          <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
          <Route path={ROUTES.FEATURES_REQUEST} element={<FeaturesRequest />} />
          {/* <Route path={ROUTES.PAYMENTS} element={<Payments />} /> */}
        </Route>
        <Route
          element={
            <RequireAuth>
              <DashboardLayoutNoMargins />
            </RequireAuth>
          }
        >
          <Route
            path={ROUTES.SINGLE_FEATURES_REQUEST}
            element={<SingleFeatureRequest />}
          />
        </Route>
      </Routes>
    </>
  );
};
export default AppRouter;
