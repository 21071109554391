import { useMemo } from 'react';

interface AvailableHoursProps {
  maxHours: number;
  currentHours: number;
}

const AvailableHours = ({ currentHours, maxHours }: AvailableHoursProps) => {
  const width = useMemo<number>(
    () => (currentHours / maxHours > 1 ? 100 : (currentHours / maxHours) * 100),
    [currentHours, maxHours],
  );
  const color = useMemo<string>(
    () => (currentHours / maxHours > 1 ? 'bg-red' : 'bg-light-green-slider'),
    [currentHours, maxHours],
  );

  return (
    <div className="flex flex-row items-center gap-x-5">
      <p className="text-dark-blue text-md font-bold">Planned Tasks</p>
      <div className="flex flex-col items-end gap-y-0.5">
        <p className="text-xs text-dark-blue">
          <b>{currentHours}</b>
          <span>{' Hours of '}</span>
          <b>{maxHours}</b>
          <span>{' Available Hours'}</span>
        </p>
        <div
          className={`min-w-[300px] h-[5px] bg-light-gray-slider rounded-lg relative`}
        >
          <div
            className={`${color} transition-[width] h-[5px] absolute left-0 top-0 rounded-lg`}
            style={{
              width: `${width}%`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AvailableHours;
