import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useSetRecoilState } from 'recoil';

import { GET_USER } from '../apollo/User';
import Sidebar from '../components/navigation/Sidebar';
import { userInfo } from '../states/atoms';

const DashboardLayout = () => {
  const setUser = useSetRecoilState(userInfo);
  const { user } = useAuth0();

  useQuery(GET_USER, {
    variables: { auth0_id: user?.sub },
    onCompleted: (data) => {
      setUser(data.getUser.data);
    },
    onError: (error) => {
      console.log(error, 'error user');
    },
    skip: !user?.sub,
  });

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        className="flex min-h-screen overflow-hidden"
        style={{
          backgroundImage: 'url("/images/background-layout.svg")',
          backgroundRepeat: 'repeat',
          backgroundPosition: 'top -200px right -400px',
        }}
      >
        <Sidebar />
        <div
          className={`size-full py-6 md:pb-10 text-blacker transition-all duration-1000 md:pl-[58px] pl-[78px] pr-[20px] md:pt-8`}
        >
          <div className="md:px-20 h-full ">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
