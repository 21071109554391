import HIGH from '../../assets/high.svg';
import LOW from '../../assets/low.svg';
import MEDIUM from '../../assets/medium.svg';

interface PriorityIconProps {
  priority?: string | null;
}

interface IconComponentProps {
  src: string;
  priority: string;
}

const IconComponent = ({ src, priority }: IconComponentProps) => {
  return <img alt={priority} src={src} width={18} height={18} />;
};

const PriorityIcon = ({ priority }: PriorityIconProps) => {
  if (priority === 'HIGH')
    return <IconComponent priority={priority} src={HIGH} />;
  if (priority === 'MEDIUM')
    return <IconComponent priority={priority} src={MEDIUM} />;
  if (priority === 'LOW')
    return <IconComponent priority={priority} src={LOW} />;
  return <></>;
};

export default PriorityIcon;
