import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import App from './App';
import ApolloWrapper from './config/ApolloWrapper';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI || '',
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
      }}
      cacheLocation="localstorage"
    >
      <RecoilRoot>
        <ApolloWrapper>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ApolloWrapper>
      </RecoilRoot>
    </Auth0Provider>
  </React.StrictMode>,
);
