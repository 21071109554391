import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

import Loader from '../loaders/RippleLoader';

const RequireAuth = ({ children }: any) => {
  const { isAuthenticated, isLoading, user, logout } = useAuth0();

  if (isLoading) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <Loader />
      </div>
    );
  }
  if (!isAuthenticated && !isLoading) {
    return (
      <Navigate
        to={{
          pathname: '/',
        }}
        replace={true}
      />
    );
  }
  if (isAuthenticated && user?.user_roles.includes('Admin')) {
    return logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI || '',
      },
    });
  }
  return children;
};

export default RequireAuth;
