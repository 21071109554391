import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../inputs/Button';
// projectComputer.png

interface ProjectInfoProps {
  project: string;
  category: 'website' | 'mobile' | 'software';
  // status: string;
  slug: string;
  logo: string;
}

export function ProjectInfo({
  project,
  category,
  slug,
  logo,
  // status,
}: ProjectInfoProps) {
  const navigate = useNavigate();
  const cateogryText = () => {
    if (category === 'mobile') return 'Mobile App';
    if (category === 'website') return 'Website';
    if (category === 'software') return 'Software';
    return 'Software';
  };
  return (
    <div className="min-h-[200px] max-w-[180px] flex flex-col items-center">
      <img
        src={`${process.env.REACT_APP_AWS_S3_PUBLIC_FOLDER}${logo}`}
        alt={project}
        width={120}
        height={70}
        className="object-contain h-[70px]"
      />
      <div className="flex items-center flex-col text-center font-semibold leading-6 mt-2">
        <p className="text-base ">{project}</p>
        <p className="text-sm">{cateogryText()}</p>
        {/* <p className="text-sm">{status}</p> */}
        <Button
          onClick={() => navigate(`/features-request/${slug}`)}
          text="Features Request"
          style="black"
          extraClassname="mt-2"
          fontSize="text-sm"
        />
      </div>
    </div>
  );
}
