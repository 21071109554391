export const TASKS_NOTIFICATIONS = {
  TASK_UPDATE_KO: 'Could not update this task. Please try again.',
  TASK_SCHEDULED_OK: 'Task scheduled succesfully.',
  TASK_SCHEDULED_KO: 'Could not schedule this task. Please try again.',
  TASK_UNSCHEDULED_OK: 'Task unscheduled succesfully.',
  TASK_UNSCHEDULED_KO: 'Could not unschedule this task. Please try again.',
  TASK_DELETED_OK: 'Task deleted succesfully.',
  TASK_DELETED_KO: 'Could not execute delete this task. Please try again.',
  MOVE_ERROR: 'Could not execute this operation. Please try again.',
  MOVE_MAX_HOURS_WARNING:
    'Planned hours exceed maximum hours for the project. No more tasks will be scheduled.',
  MOVE_MAX_HOURS_ERROR:
    'Current planned hours already exceed maximum hours for the project.',
  MOVE_NO_HOURS_ERROR: 'Cannot schedule a task without estimated hours.',
  CREATE_OK: 'Task created succesfully',
  CREATE_KO: 'Could not execute this operation. Please try again.',
};

export const PROJECT_NOTIFICATIONS = {
  UPDATE_MAX_HOURS_ERROR: 'Could not execute this operation. Please try again.',
  UPDATE_MAX_HOURS_OK: 'Project hours updated succefully.',
};
