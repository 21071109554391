import './styles/App.css';
import './styles/index.css';
import './styles/custom.css';
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';

import AppRouter from './config/routes';

function App() {
  return <AppRouter />;
}

export default App;
