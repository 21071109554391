import Button from '../../components/inputs/Button';
import PopupLayout from '../../layouts/PopupLayout';

interface GenericConfirmationPopupProps {
  text: string;
  description?: string;
  acceptText?: string;
  onAccept: () => void;
  closePopup: () => void;
  loading?: boolean;
  visible: boolean;
  bgStyle?: 'dark' | 'blur';
}

const GenericConfirmationPopup = ({
  closePopup,
  onAccept,
  acceptText = 'Accept',
  description,
  text,
  loading,
  visible,
  bgStyle = 'blur',
}: GenericConfirmationPopupProps) => {
  if (!visible) return <></>;

  return (
    <PopupLayout
      bgStyle={bgStyle}
      popupClassname="md:max-w-[450px] min-w-[250px]"
      handleClosePopup={closePopup}
    >
      <div className="flex flex-col gap-y-3">
        <div className="flex flex-col gap-y-1.5">
          <p className="text-xl font-bold text-dark-blue">{text}</p>
          {description && (
            <p className="text-sm text-light-gray-text">{description}</p>
          )}
        </div>

        <div className="flex flex-row justify-end gap-x-5">
          <Button
            style="black-cancel"
            text="Cancel"
            type="submit"
            onClick={closePopup}
          />
          <Button
            text={acceptText}
            type="submit"
            loading={loading}
            onClick={onAccept}
          />
        </div>
      </div>
    </PopupLayout>
  );
};

export default GenericConfirmationPopup;
