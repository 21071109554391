import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { GET_SINGLE_PROJECTS } from '../../apollo/Projects';
import FeaturesTaskRequests from '../../components/features-request/FeaturesTaskRequests';
import type { IColumn, IGetSingleProjectRes } from '../../types/Projects';
// import ProjectHoursPopup from '../Popups/ProjectHoursPopup';

function SingleFeatureRequest() {
  const { slug } = useParams();
  const [data, setData] = useState<IColumn[] | null>(null);
  const [maxHours, setMaxHours] = useState<number>();
  const [currentHours, setCurrentHours] = useState<number>();
  const [projectId, setProjectId] = useState<string>();

  const { loading, refetch } = useQuery<IGetSingleProjectRes>(
    GET_SINGLE_PROJECTS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: { slug },
      onCompleted: (dataProjects) => {
        const newData = Array.of(
          ...dataProjects.getSingleProject.data.columns!,
        );
        newData.sort((a, b) => a.order - b.order);
        /**
         * JSON.parse and JSON.stringify needed in here to make handleOnDragEnd
         * funcionality work. Without it lines where we assign something different
         * to newData variable would crash due to the variable being read only.
         * With this line we create a new editable instance of the variable
         * received as a response.
         */
        setData(JSON.parse(JSON.stringify(newData)));
        // Set Max Hours
        setMaxHours(dataProjects.getSingleProject.data.max_hours!);
        setProjectId(dataProjects.getSingleProject.data.project_id);
        setCurrentHours(dataProjects.getSingleProject.data.hoursEstimated || 0);
      },
      onError: (error) => {
        console.log(error, 'error user');
      },
    },
  );

  return (
    <>
      <div className={`size-full text-blacker transition-all duration-1000`}>
        <div className="h-full overflow-auto md:pl-20 md:pr-10 px-[20px]">
          <div className="flex flex-col py-6 md:pb-10 md:pt-8 w-max">
            <div className="text-dark-blue font-inter text-2xl font-semibold text-left">
              Features Request
            </div>
            <div className="flex flex-col gap-y-5 mt-5">
              <FeaturesTaskRequests
                data={data}
                setData={setData}
                loading={!data && loading}
                maxHours={maxHours || 0}
                currentHours={currentHours || 0}
                setCurrentHours={setCurrentHours}
                refetch={refetch}
                project_id={projectId!}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SingleFeatureRequest;
